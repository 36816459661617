import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card p-5" }
const _hoisted_2 = { class: "btn btn-sm btn-light btn-active-light-primary" }
const _hoisted_3 = { class: "svg-icon svg-icon-2 me-0" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ExportCustomerModal = _resolveComponent("ExportCustomerModal")!
  const _component_AddAgentModal = _resolveComponent("AddAgentModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_table, { data: _ctx.tableData }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "firstname",
            label: "Firstname"
          }),
          _createVNode(_component_el_table_column, {
            prop: "lastname",
            label: "Lastname"
          }),
          _createVNode(_component_el_table_column, {
            prop: "email",
            label: "Email"
          }),
          _createVNode(_component_el_table_column, {
            prop: "isActive",
            label: "Active",
            "filter-placement": "bottom-end"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_tag, {
                type: scope.row.isActive === true ? 'success' : 'warning',
                "disable-transitions": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.isActive), 1)
                ]),
                _: 2
              }, 1032, ["type"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "roles",
            label: "Role",
            "filter-placement": "bottom-end"
          }, {
            default: _withCtx((scope) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.roles, (item) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: item.id,
                  class: "btn btn-bg-light"
                }, _toDisplayString(item.name), 1))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { align: "right" }, {
            header: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                size: "mini",
                placeholder: "Type to search"
              }, null, 8, ["modelValue"])
            ]),
            default: _withCtx((scope) => [
              _createVNode(_component_el_dropdown, null, {
                dropdown: _withCtx(() => [
                  _createVNode(_component_el_dropdown_menu, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: "/apps/agents/agents-details",
                            class: "menu-link px-3"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("View")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_dropdown_item, null, {
                        default: _withCtx(() => [
                          _createElementVNode("a", {
                            onClick: ($event: any) => (_ctx.deleteRecord(scope.$index, scope.row)),
                            class: "menu-link px-3"
                          }, "Delete ", 8, _hoisted_4)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("a", _hoisted_2, [
                    _createTextVNode(" Actions "),
                    _createElementVNode("span", _hoisted_3, [
                      _createVNode(_component_inline_svg, {
                        src: "/media/icons/duotune/arrows/arr072.svg",
                        "aria-hidden": "true"
                      })
                    ])
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _createVNode(_component_ExportCustomerModal),
    _createVNode(_component_AddAgentModal)
  ]))
}