
import { defineComponent, ref, onMounted, computed, reactive, toRefs } from "vue";
import ExportCustomerModal from "@/components/divere/modals/forms/ExportCustomerModal.vue";
import AddAgentModal from "@/components/divere/modals/forms/AddAgentModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IUser } from "@/store/modules/divere/UserModule";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import customers, { ICustomer } from "@/core/data/customers";

export default defineComponent({
  name: "customers-listing",
  components: {
    // Datatable,
    ExportCustomerModal,
    AddAgentModal,
  },
  setup() {
    const store = useStore();
    const checkedCustomers = ref([]);


    const tableData = computed(() => store.getters.getAgentList)

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Agents Listing", ["Apps", "Agents"]);
    });

    onMounted(() => {
      store
        .dispatch(Actions.REQ_AGENT_LIST)
        .catch(() => {
          Swal.fire({
            text: store.getters.getUserErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-dark-blue",
            },
          });
        });
    })

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const deleteAgent = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };
    const filterTag = (value, row) =>{
      return row.tag === value
    }

    const search = ref<string>("");
    const searchItems = () => {
      console.log();

    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      deleteAgent,
      checkedCustomers,
      deleteFewCustomers,
      filterTag
    };
  },
});
