
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "add-agent-modal",
  components: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addAgentModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      firstname: "",
      lastname: "",
      email: "",
      roles: [],
    });
    const roleOptions = ref([
      {
        value: "1",
        label: "Super Admin",
      },
      {
        value: "2",
        label: "Editor",
      },
      {
        value: "3",
        label: "Authors",
      },
    ]);

    const rules = ref({
      firstname: [
        {
          required: true,
          message: "Agent firstname is required",
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: "Agent lastname is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Agent email is required",
          trigger: "change",
        },
      ],
      roles: [
        {
          required: true,
          message: "Role is required",
          trigger: "select",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          setTimeout(() => {
            loading.value = false;

            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-dark-blue",
              },
            }).then(() => {
              hideModal(addAgentModalRef.value);
            });
          }, 2000);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-dark-blue",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      roleOptions,
      submit,
      formRef,
      loading,
      addAgentModalRef,
    };
  },
});
